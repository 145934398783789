import React from 'react';

import Head from 'next/head';
import {usePersonState} from '../modules/person/context';

const Home = (): JSX.Element => {
    const person = usePersonState();
    return (
        <div>
            <Head>
                <title>Home</title>
            </Head>
            <div>{`Logged in: ${person.isLoggedIn}`}</div>
        </div>
    );
};

export default Home;
